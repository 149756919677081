<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-text class="d-flex justify-center align-center py-0 pt-5" style="height: 150px;">
        <span
          class="text-h6"
        >見積金額を決定します。決定された後、見積金額の変更ができません。決定しますか？</span>
      </v-card-text>
      <v-card-actions align="center" class="d-flex justify-center">
        <v-btn
          class="mx-2"
          color="primary"
          :loading="loading"
          @click="submit()"
        >
          決定
        </v-btn>
        <v-btn
          class="mx-2"
          outlined
          @click="closeForm()"
        >
          CANCEL
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mapActions,
} from 'vuex'

export default {
  props: ['dialog', 'selectedBiddings'],
  data: () => ({
    apierror: [],
    loading: false,
  }),
  computed: {
  },
  watch: {
  },
  methods: {
    ...mapActions('bidStore', ['finalizeQuotation']),

    closeForm() {
      console.log('closeForm')
      this.$emit('closeForm')
    },

    submit() {
      this.loading = true
      const submitForm = {
        details: this.selectedBiddings,
      }

      console.log('finalize,submitForm:', submitForm)

      this.finalizeQuotation(submitForm)
        .then(() => {
          this.closeForm()
        })
        .catch(error => {
          console.log('error:', error)
          this.apierror.status = 'error'
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        }).finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
