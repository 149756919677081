var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("落札済見積(一覧)")]),_c('v-row',{staticClass:"px-2 ma-0"},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.customerMaster,"placeholder":"取引先","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.cust_abbr),callback:function ($$v) {_vm.cust_abbr=$$v},expression:"cust_abbr"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.pelletIds,"placeholder":"Pellet","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.pellet_id),callback:function ($$v) {_vm.pellet_id=$$v},expression:"pellet_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.auctionCodes,"placeholder":"入札商品","hide-details":"","dense":"","outlined":"","clearable":""},model:{value:(_vm.auc_code),callback:function ($$v) {_vm.auc_code=$$v},expression:"auc_code"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.quotStatusMaster,"placeholder":"見積状態","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.quot_stat),callback:function ($$v) {_vm.quot_stat=$$v},expression:"quot_stat"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"placeholder":"落札日From","dense":"","outlined":"","hide-details":"","prepend-icon":_vm.icons.mdiCalendar,"clearable":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateStartMenu),callback:function ($$v) {_vm.dateStartMenu=$$v},expression:"dateStartMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateStartMenu = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"placeholder":"落札日To","dense":"","outlined":"","hide-details":"","prepend-icon":_vm.icons.mdiCalendar,"clearable":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateEndMenu),callback:function ($$v) {_vm.dateEndMenu=$$v},expression:"dateEndMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateEndMenu = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-4 me-3",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlaylistEdit)+" ")]),_c('span',[_vm._v("一括操作")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":!_vm.bCanCreate},on:{"click":_vm.openCrteatDialog}},[_c('v-list-item-icon',{staticClass:"pr-1 ma-auto"},[_c('v-icon',{attrs:{"disabled":_vm.OperationDisabled}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileCheckOutline)+" ")])],1),_c('v-list-item-content',{staticClass:"d-flex align-center"},[_c('v-list-item-title',[_vm._v("見積作成")])],1)],1),_c('v-list-item',{attrs:{"disabled":!_vm.bCanFinalize},on:{"click":_vm.openEditDialog}},[_c('v-list-item-icon',{staticClass:"pr-1 ma-auto"},[_c('v-icon',{attrs:{"disabled":!_vm.bCanFinalize}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileSendOutline)+" ")])],1),_c('v-list-item-content',{staticClass:"d-flex align-center"},[_c('v-list-item-title',[_vm._v("見積決定")])],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"disabled":!_vm.bCanDelete},on:{"click":_vm.openDeleteDialog}},[_c('v-list-item-icon',{staticClass:"pr-1 ma-auto",attrs:{"disabled":!_vm.bCanDelete}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiFileRemoveOutline)+" ")])],1),_c('v-list-item-content',{staticClass:"d-flex align-center"},[_c('v-list-item-title',[_vm._v("見積破棄")])],1)],1)],1)],1),_c('v-btn',{staticClass:"mb-4",attrs:{"color":"secondary","outlined":""}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiExportVariant)+" ")]),_c('span',[_vm._v("Export")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.biddings,"item-key":"bid_id","show-select":""},scopedSlots:_vm._u([{key:"item.bid_id",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.pushProgress(item)}}},[_vm._v(_vm._s(item.bid_id))])]}},{key:"item.auc_id",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.pushBidDetail(item)}}},[_vm._v(" "+_vm._s(item.auc_id))])]}},{key:"item.cust_abbr",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({style:('text-decoration: underline; color:#666666')},'a',attrs,false),on),[_vm._v(_vm._s(item.cust_abbr))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.cust_id))]),_c('br'),_c('span',[_vm._v(_vm._s(item.cust_name))]),_c('br'),_c('span',[_vm._v(_vm._s(item.cust_name_eng))]),_c('br')])]}},{key:"item.bid_stat_desc",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-chip',{staticClass:"d-flex justify-center",staticStyle:{"width":"65px"},attrs:{"color":_vm.getColor(item.bid_stat)}},[_c('span',[_vm._v(_vm._s(item.bid_stat_desc))])])],1)]}},{key:"item.bid_weight",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.bid_weight ? _vm.formatData(item.bid_weight) : '0'))])]}},{key:"item.bid_amount",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(_vm.formatAmount(item.ccy_mark,item.bid_amount)))])]}},{key:"item.quot_f_amount",fn:function(ref){
var item = ref.item;
return [(item.quot_f_amount>0)?_c('label',[_vm._v(_vm._s(_vm.formatAmount(item.ccy_mark,item.quot_f_amount)))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.quot_id !== null)?_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilePdfBox)+" ")]):_vm._e(),_c('v-icon',{attrs:{"small":"","disabled":item.quot_id ===null},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])]}}],null,true),model:{value:(_vm.selectedBiddings),callback:function ($$v) {_vm.selectedBiddings=$$v},expression:"selectedBiddings"}}),_c('QuotationCretae',{attrs:{"dialog":_vm.createDialog,"selected-biddings":_vm.selectedBiddings},on:{"closeForm":_vm.closeCreateDialog}}),_c('QuotationFinalize',{attrs:{"dialog":_vm.editDialog,"selected-biddings":_vm.selectedBiddings},on:{"closeForm":_vm.closeEditDialog}}),_c('QuotationDelete',{attrs:{"dialog":_vm.deleteDialog,"selected-biddings":_vm.selectedBiddings},on:{"closeForm":_vm.closeDeleteDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }