<template>
  <v-card>
    <v-card-title>落札済見積(一覧)</v-card-title>
    <v-row class="px-2 ma-0">
      <v-col cols="12" md="2">
        <v-autocomplete
          v-model="cust_abbr"
          :items="customerMaster"
          placeholder="取引先"
          outlined
          clearable
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-autocomplete
          v-model="pellet_id"
          :items="pelletIds"
          placeholder="Pellet"
          outlined
          clearable
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-autocomplete
          v-model="auc_code"
          :items="auctionCodes"
          placeholder="入札商品"
          hide-details
          dense
          outlined
          clearable
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="2">
        <v-autocomplete
          v-model="quot_stat"
          :items="quotStatusMaster"
          placeholder="見積状態"
          outlined
          clearable
          dense
          hide-details
        ></v-autocomplete>
      </v-col>

      <v-col
        cols="12"
        md="2"
      >
        <v-menu
          v-model="dateStartMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              placeholder="落札日From"
              dense
              outlined
              hide-details
              v-bind="attrs"
              :prepend-icon="icons.mdiCalendar"
              clearable
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            @input="dateStartMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-menu
          v-model="dateEndMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDate"
              placeholder="落札日To"
              dense
              outlined
              hide-details
              v-bind="attrs"
              :prepend-icon="icons.mdiCalendar"
              clearable
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="endDate"
            @input="dateEndMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-divider class="mt-4"></v-divider>
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-text-field
        v-model="searchQuery"
        placeholder="Search"
        outlined
        hide-details
        dense
        class="user-search me-3 mb-4"
      >
      </v-text-field>
      <v-spacer></v-spacer>
      <div class="d-flex align-center flex-wrap">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mb-4 me-3" color="primary" dark v-bind="attrs" v-on="on">
              <v-icon size="17" class="me-1">
                {{ icons.mdiPlaylistEdit }}
              </v-icon>
              <span>一括操作</span>
            </v-btn>
          </template>
          <v-list>
            <!-- <v-list-item :disabled="OperationDisabled" @click="openOperationDialog"> -->
            <v-list-item :disabled="!bCanCreate" @click="openCrteatDialog">
              <v-list-item-icon class="pr-1 ma-auto">
                <v-icon :disabled="OperationDisabled">
                  {{ icons.mdiFileCheckOutline }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content class="d-flex align-center">
                <v-list-item-title>見積作成</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :disabled="!bCanFinalize" @click="openEditDialog">
              <v-list-item-icon class="pr-1 ma-auto">
                <v-icon :disabled="!bCanFinalize">
                  {{ icons.mdiFileSendOutline }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content class="d-flex align-center">
                <v-list-item-title>見積決定</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item :disabled="!bCanDelete" @click="openDeleteDialog">
              <v-list-item-icon :disabled="!bCanDelete" class="pr-1 ma-auto">
                <v-icon>
                  {{ icons.mdiFileRemoveOutline }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content class="d-flex align-center">
                <v-list-item-title>見積破棄</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn color="secondary" outlined class="mb-4">
          <v-icon size="17" class="me-1">
            {{ icons.mdiExportVariant }}
          </v-icon>
          <span>Export</span>
        </v-btn>
      </div>
    </v-card-text>

    <v-data-table
      v-model="selectedBiddings"
      :search="search"
      :headers="headers"
      :items="biddings"
      class="elevation-1"
      item-key="bid_id"
      show-select
    >
      <template v-slot:[`item.bid_id`]="{ item }">
        <a
          href="javascript:void(0);"
          @click="pushProgress(item)"
        >{{ item.bid_id }}</a>
      </template>
      <template v-slot:[`item.auc_id`]="{ item }">
        <a
          href="javascript:void(0);"
          @click="pushBidDetail(item)"
        > {{ item.auc_id }}</a>
      </template>
      <template v-slot:[`item.cust_abbr`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <a
              v-bind="attrs"
              :style="'text-decoration: underline; color:#666666' "
              v-on="on"
            >{{ item.cust_abbr }}</a>
          </template>
          <span>{{ item.cust_id }}</span><br>
          <span>{{ item.cust_name }}</span><br>
          <span>{{ item.cust_name_eng }}</span><br>
        </v-tooltip>
      </template>
      <template v-slot:[`item.bid_stat_desc`]="{ item }">
        <div class="d-flex justify-end">
          <v-chip
            style="width:65px"
            :color="getColor(item.bid_stat)"
            class="d-flex justify-center"
          >
            <span>{{ item.bid_stat_desc }}</span>
          </v-chip>
        </div>
      </template>
      <template v-slot:[`item.bid_weight`]="{ item }">
        <label>{{ item.bid_weight ? formatData(item.bid_weight) : '0' }}</label>
      </template>
      <template v-slot:[`item.bid_amount`]="{ item }">
        <label>{{ formatAmount(item.ccy_mark,item.bid_amount) }}</label>
      </template>

      <template v-slot:[`item.quot_f_amount`]="{ item }">
        <label v-if="item.quot_f_amount>0">{{ formatAmount(item.ccy_mark,item.quot_f_amount) }}</label>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon v-if="item.quot_id !== null" class="mr-2" @click="viewItem(item)">
          {{ icons.mdiFilePdfBox }}
        </v-icon>
        <v-icon small :disabled="item.quot_id ===null" @click="editItem(item)">
          {{ icons.mdiPencil }}
        </v-icon>
      </template>
    </v-data-table>
    <QuotationCretae
      :dialog="createDialog"
      :selected-biddings="selectedBiddings"
      @closeForm="closeCreateDialog"
    ></QuotationCretae>
    <QuotationFinalize
      :dialog="editDialog"
      :selected-biddings="selectedBiddings"
      @closeForm="closeEditDialog"
    ></QuotationFinalize>
    <QuotationDelete
      :dialog="deleteDialog"
      :selected-biddings="selectedBiddings"
      @closeForm="closeDeleteDialog"
    ></QuotationDelete>
  </v-card>
</template>

<script>

// import AuctionTable from './tables/AuctionTable.vue'
import {
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiClose,
  mdiExportVariant,
  mdiFilePdfBox,
  mdiFileCheckOutline,
  mdiFileSendOutline,
  mdiFileRemoveOutline,
} from '@mdi/js'
import {
  mapActions, mapMutations, mapState,
} from 'vuex'
import QuotationCretae from './QuotationCreate.vue'
import QuotationFinalize from './QuotationFinalize.vue'
import QuotationDelete from './QuotationDelete.vue'

export default {
  components: {
    QuotationCretae,
    QuotationFinalize,
    QuotationDelete,
  },
  data: () => ({
    pellet_id: '',

    search: '',
    searchQuery: '',
    date: '',
    startDate: '',
    endDate: '',
    dateEndMenu: false,
    dateStartMenu: false,
    selectId: '',
    counter: 0,
    auctionCodes: [],
    auc_code: '',
    auc_id: '',
    cust_abbr: '',
    searchBar: {
    },

    bCanCreate: false,
    bCanDelete: false,
    bCanFinalize: false,

    selectedBiddings: [],
    email: '',
    dialog: false,
    dialogDelete: false,
    createDialog: false,
    editDialog: false,
    deleteDialog: false,
    icons: {
      mdiPencil,
      mdiDelete,
      mdiCalendar,
      mdiClose,
      mdiExportVariant,
      mdiFilePdfBox,
      mdiFileCheckOutline,
      mdiFileSendOutline,
      mdiFileRemoveOutline,
    },
    quot_stat: '',

    OperationDisabled: true,
    headers: [

      {
        text: 'ID.',
        value: 'bid_id',
        align: 'left',
        width: '8%',
      },
      {
        text: 'Pellet',
        value: 'pellet_id',
        fixed: true,
        align: 'left',
        width: '10%',
      },
      {
        text: '商品コード',
        align: 'left',
        fixed: true,
        value: 'auc_code',
        width: '12%',
      },

      {
        text: '取引先',
        value: 'cust_abbr',
        align: 'left',
        width: '12%',
      },
      {
        text: 'Qty',
        value: 'bid_cnt',
        align: 'right',
        width: '6%',
      },
      {
        text: '重量(KG)',
        value: 'bid_weight',
        align: 'right',
        width: '10%',
      },
      {
        text: '入札金額',
        value: 'bid_amount',
        align: 'right',
        width: '10%',
      },

      {
        text: '見積金額',
        value: 'quot_f_amount',
        align: 'right',
        width: '10%',
      },

      {
        text: '状態',
        value: 'quot_stat_desc',

        align: 'right',
        width: '10%',
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        align: 'right',
        width: '10%',
      },
    ],
  }),

  computed: {
    ...mapState('pelletStore', ['pelletIds']),
    ...mapState('quotationStore', ['quotStatusMaster']),
    ...mapState('customerStore', ['customerMaster']),
    ...mapState('bidStore', ['biddings', 'bidStatusMaster']),

    formatAmount() {
      return function (ccyMark, amount) {
        // if (ccyMark === undefined || ccyMark === null) return ''
        // if (amount === undefined || amount === null) return ''

        // return ccyMark + amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        if (typeof amount === 'number') {
          return `${ccyMark ?? ''}${amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        }
        if (typeof amount === 'string') {
          return `${ccyMark ?? ''}${parseFloat(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        }

        return ''
      }
    },

    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
  },

  watch: {

    selectedBiddings(vals) {
      if (vals.length === 0) {
        this.bCanCreate = false
        this.bCanDelete = false
        this.bCanFinalize = false

        return
      }

      this.bCanCreate = true
      this.bCanDelete = true
      this.bCanFinalize = true

      console.log('count:', vals.length)

      // 見積作成可を判断する
      for (let i = 0; i < vals.length; i += 1) {
        if (vals[i].quot_id !== null) {
          this.bCanCreate = false
          break
        }
      }

      // 見積決定の可否を判断する
      for (let i = 0; i < vals.length; i += 1) {
        console.log(i, vals[i])
        if (vals[i].quot_stat === 2) {
          console.log(i, vals[i].quot_stat)
          this.bCanFinalize = false
          break
        }
      }

      // 見積破棄の可否を判断する
      for (let i = 0; i < vals.length; i += 1) {
        if (vals[i].quot_stat === null) {
          this.bCanDelete = false
          break
        }
      }
    },

    pellet_id(val) {
      this.changeRouteQuery('pellet_id', val)
      this.loadData()
    },
    auc_code(val) {
      this.changeRouteQuery('auc_code', val)
      this.loadData()
    },
    cust_abbr(val) {
      this.changeRouteQuery('cust_id', val)
      this.loadData()
    },

    quot_stat(val) {
      this.changeRouteQuery('quot_stat', val)
      this.loadData()
    },
    startDate(val) {
      this.changeRouteQuery('startDate', val)
      this.loadData()
    },
    endDate(val) {
      this.changeRouteQuery('endDate', val)
      this.loadData()
    },
  },

  created() {
    this.loadQuery()

    this.changeRouteQuery()

    this.loadData()

    // this.initialize()
  },

  methods: {
    ...mapActions('pelletStore', ['loadPelletIds']),
    ...mapActions('customerStore', ['loadCustomerIds']),
    ...mapActions('bidStore', ['loadBiddingStatus', 'loadWonBiddings', 'createQuotation']),
    ...mapMutations('app', ['setOverlayStatus']),

    ...mapActions('quotationStore', ['loadQuotationStatus']),

    pushProgress(item) {
      this.$router.push({
        name: 'bidding-progress',
        params: {
          id: item.bid_id,
          from: 2,
        },
      })
    },

    toCreateQuotation() {
      console.log('creteQuotations selectedBiddings:', this.selectedBiddings)

      const submitForm = {
        details: this.selectedBiddings,
      }
      console.log('submitForm:', submitForm)

      this.createQuotation(submitForm)
        .then(() => {
          this.closeForm()
        })
        .catch(error => {
          this.apierror.status = 'error'
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        })
    },

    openCrteatDialog() {
      this.createDialog = true
    },
    closeCreateDialog() {
      this.selectedBiddings = []
      this.createDialog = false
      this.loadData()
    },
    openEditDialog() {
      this.editDialog = true
    },
    closeEditDialog() {
      this.selectedBiddings = []
      this.editDialog = false
      this.loadData()
    },
    openDeleteDialog() {
      this.deleteDialog = true
    },
    closeDeleteDialog() {
      this.selectedBiddings = []
      this.deleteDialog = false
      this.loadData()
    },

    editItem(item) {
      console.log('approved edit item:', item)
      this.$router.push({
        name: 'biddingwon-edit',
        params: {
          id: item.quot_id,
        },
      })
    },

    toDeleteQuotation() {
      console.log('Delete Quotation.')

      const submitForm = {
        result: 9,
        details: this.selectedReserveds,
      }

      console.log('submitForm:', submitForm)

      // this.deleteQuotation(submitForm)
    },

    viewItem(item) {
      console.log('view', item)
      this.$router.push({
        name: 'bidwon-quotation',
        params: {
          id: item.quot_id,
        },
      })
    },

    loadQuery() {
      this.pellet_id = this.$route.query.pellet_id ?? ''
      this.cust_abbr = this.$route.query.cust_abbr ?? ''
      this.startDate = this.$route.query.start_date ?? ''
      this.endDate = this.$route.query.end_date ?? ''
    },

    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }

      this.$router.push({
        path: this.$route.path, query,
      })
    },

    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadCustomerIds(),
        this.loadPelletIds(),
        this.loadQuotationStatus(),
        this.loadWonBiddings(this.$route.query),

      ]).then(() => {
        console.log('biddings', this.biddings)
        this.auctionCodes = this.biddings.map(b => b.auc_code)
      }).catch(error => {
        console.log('loadData', error)
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },

    pushBidDetail(item) {
      this.$router.push({
        name: 'bidding-determination',
        params: {
          id: item.auc_id,
        },
      })
    },

    showConfirmDialog() {
      this.dialog = true
    },

    // searchBtnClicked() {
    //   this.filters = JSON.parse(JSON.stringify(this.searchBar))

    //   // change value trigger search event
    //   this.counter += 1
    //   this.search = `${this.counter}`
    // },

    getColor(status) {
      if (status === 1) {
        return 'primary'
      } if (status === 2) {
        return 'error'
      } if (status === 9) {
        return 'success'
      }

      return 'white'
    },

  },
}
</script>
<style>
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
</style>
